import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import {
  getAuth,
  signOut,
  deleteUser,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { InsMonitorModel, UserInfo } from '@/types/home';
import { useTranslation } from 'next-i18next';
import { accountDelete, getUserInfo, loginRedirect, userUpdate } from '@/lib/service';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { IUserInfoResBody, userCombine, userSubInfo } from '@/lib/service/instantknow';
import { kTmpUidKey } from '@/shared/app-common';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash-es';

type UserProviderType = {
  /**
   * total cart quantity
   */
  // total?: number;
  openLogin?: boolean;
  isLoginView?: boolean;
  openLoginResult?: boolean;
  accountList?: InsMonitorModel[];
  /**
   * add cart callback function
   * @param params Product information added
   */
  onAddShop?: <P = any>(params: P) => Promise<{ data: P }>;
  onDeleteShopCarItem?: <P = any>(params: P) => Promise<{ data: P }>;
  onOpenLoginModal?: (open: boolean) => void;
  onChangedLoginView?: (isLoginView: boolean) => void;
  onChangedLoginResult?: (isLoginView: boolean) => void;
  onAddToken?: (token?: string, signInMethod?: string, idToken?: string) => void;
  onInitAccountList?: (accountList?: InsMonitorModel[]) => void;
  onAddAccount?: (account?: InsMonitorModel) => void;
  onSignOut?: (showAlert?: boolean) => void;
  onDeleteUser?: () => void;

  /**
   * Refresh cart data
   */
  onRefreshCartData?: () => Promise<void>;

  isLogin?: boolean;
  userInfo?: UserInfo;
  setUserInfo?: any;
  userCurrentPlan?: IUserInfoResBody;
  updateUserCurrentPlan?: (model?: IUserInfoResBody) => void;
  userUse?: any;
};

const Context = createContext<UserProviderType>({});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  // const { message } = App.useApp();

  const router = useRouter();
  // const [totalCartQuantity, setTotalCartQuantity] = useState<number>();
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [isLoginView, setIsLoginView] = useState<boolean>(true);
  const [openLoginResult, setOpenLoginResult] = useState<boolean>(false);
  const [accountList, setAccountList] = useState([]);

  const [userInfo, setUserInfo] = useState<UserInfo>(null);
  const [userCurrentPlan, setUserCurrentPlan] = useState<IUserInfoResBody>(null);

  const loadUserInfo = () => {
    const token = Cookies.get('ika_userToken');
    if (token) {
      getUserInfo().then((res) => {
        setUserInfo(res?.data);
        loadCurrentPlanData()
      });
    } else {
      setUserInfo(null);
      setUserCurrentPlan(null);
    }
  };

  const loadCurrentPlanData = useCallback(
    debounce(() => {
      const token = Cookies.get('ika_userToken');

      if (!token) {
        setUserCurrentPlan(null);
        return;
      }
      userSubInfo()
        .then((res) => {
          if (res?.code === 0) {
            setUserCurrentPlan(res?.data);
          } else {
            setUserCurrentPlan(null);
          }
        })
        .catch((error) => {
          setUserCurrentPlan(null);
        })
    }, 1), // Adjust the debounce delay as needed
    [Cookies.get('ika_userToken')]
  );

  useEffect(() => {
    loadUserInfo()
  }, []);

  const updateUserCurrentPlan = (model?: IUserInfoResBody) => {
    setUserCurrentPlan(model)
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      // setTimeout(() => {
      loadUserData();
      // }, 10);
    }
  }, []);

  const getToken = (id: string, signInMethod: string) => {
    const pathUrl =
      typeof window !== undefined ? window.location.href : process.env.NEXT_APP_WEB_HOST;
    // loginRedirect(id, pathUrl, router.locale).then((res) => {
    //   if (res?.code == 0) {
    //     // onAddToken(res?.data?.token, signInMethod, id);
    //     // // onOpenLoginModal(false);
    //     // window.location.reload()
    //   } else {
    //     message.error(res.message);
    //   }
    // });

    userUpdate(id, router.locale).then((res) => {
      if (res?.data?.first_login) {
        if (res.code == 0) {
          ga_logEvent(EventName.user_register_Success);
        } else {
          ga_logEvent(EventName.user_register_Fail);
        }
      }

      if (res?.code == 0) {
        // window.location.reload()
        // onAddToken(res?.data?.token, signInMethod, id);
        // onOpenLoginModal(false);
        router.replace(`/login-success?token=${res?.data?.token}&url=${pathUrl}`);
        ga_logEvent(EventName.user_Login_Success);

        userCombine()
      } else {
        message.error(res.message);

        const auth = getAuth();
        signOut(auth);
      }
    });
  };

  const loadUserData = () => {
    const auth = getAuth();
    getRedirectResult(auth)
      .then(async (result) => {
        if (result) {
          const method = result?.providerId === 'google.com' ? 'Google' : 'Apple';
          let credential;
          if (method === 'Google') {
            credential = GoogleAuthProvider.credentialFromResult(result);
          } else {
            credential = OAuthProvider.credentialFromResult(result);
          }

          const token = credential.accessToken;
          Cookies.set('accessToken', token, { expires: 7 });
          // ls_saveItem('accessToken', token)

          result.user.getIdToken().then((id) => {
            getToken(id, method);
            ga_logEvent(EventName.login, { method: method });
          });
        }
      })
      .catch((e) => {
        message.error(e);
        router?.back();
      });
  };

  const onOpenLoginModal = (open: boolean) => {
    setOpenLogin(open);
  };

  const onChangedLoginView = (open: boolean) => {
    setIsLoginView(open);
  };

  const onChangedLoginResult = (open: boolean) => {
    setOpenLoginResult(open);
  };

  // const handleRefreshCartData = async () => {
  //   return new Promise<void>((resolve) => {
  //     message.success('Refresh successfully', 1.2, () => resolve(null));
  //   });
  // };

  const onAddToken = (token?: string, signInMethod?: string, idToken?: string) => {
    if (token != undefined) {
      Cookies.set('ika_userToken', token, { expires: 7 });
      Cookies.set('SignInMethod', signInMethod, { expires: 7 });
      Cookies.set('IdToken', idToken, { expires: 7 });

      // ls_saveItem('ika_userToken', token);
      // ls_saveItem('SignInMethod', signInMethod);
      // ls_saveItem('IdToken', idToken);

      setTimeout(() => {
        loadUserData();
      }, 10);
    } else {
      Cookies.remove('ika_userToken');
      Cookies.remove('SignInMethod');
      Cookies.remove('IdToken');

      setUserInfo(null);
    }
  };

  const clearAllCookies = () => {
    const cookies = document.cookie.split(';');
    // 遍历并删除每个cookie
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      Cookies.remove(name);
    }
  };

  const onSignOut = (showAlert?: boolean) => {
    clearAllCookies();
    if (showAlert) {
      message.success(t('SignOutSuccessful'));
      const newUuid = uuidv4();
      Cookies.set(kTmpUidKey, newUuid);
    }
    onAddToken(undefined);
    setUserInfo(null);
  };

  const onDeleteUser = () => {
    accountDelete()
      .then((res) => {
        if (res.code === 0) {
          onSignOut(false);
          setUserInfo(null);
          message.success(t('UserDeleted'));
          router.replace('/');
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        message.error(error?.message);
        onChangedLoginView(true);
        onOpenLoginModal(true);
      });
  };

  const exposed = {
    openLogin,
    isLoginView,
    openLoginResult,
    // total: totalCartQuantity,
    isLogin: Cookies.get('ika_userToken')?.length > 0,
    accountList,
    onOpenLoginModal,
    onChangedLoginView,
    onChangedLoginResult,
    onAddToken,
    onSignOut,
    onDeleteUser,
    userInfo,
    setUserInfo,
    userCurrentPlan,
    updateUserCurrentPlan,
    // userUse,
    // UpdateUserUs,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useUserProvider = () => useContext(Context);

export default UserProvider;
