import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider from '@/context/ConfigProvider';
import withTheme from '@/theme';
import '@/lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
import UserProvider, { useUserProvider } from '@/context/UserProvider';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { v4 as uuidv4 } from 'uuid';
import { kTmpUidKey } from '@/shared/app-common';
import { Inter } from 'next/font/google';
import '../styles/globals.less'; // 引入你的全局样式
import Cookies from 'js-cookie';

const inter = Inter({
  subsets: ['latin'], // 可以根据需要选择语言子集
  variable: '--font-inter', // 使用 CSS 变量
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/ika-theme.less');

const Login = dynamic(() => import('@/components/Login'), { ssr: false });
const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
  ssr: false,
});
// const LoginResult = dynamic(() => import('@/components/LoginResult'), {
//   ssr: true,
// });
const ArticleBottomAlert = dynamic(() => import('@/components/Common/ArticleBottomAlert'), {
  ssr: false,
});

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  // const { isShowArticleBottomAlert } = useConfigProvider();
  const { isLogin } = useUserProvider();

  useEffect(() => {
    if (isLogin) {
      Cookies.remove(kTmpUidKey);
    } else {
      const storedUuid = Cookies.get(kTmpUidKey);

      if (!storedUuid) {
        const newUuid = uuidv4();
        Cookies.set(kTmpUidKey, newUuid);
      }
    }
  }, [isLogin]);

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <AntdApp>
      <ConfigProvider>
        <UserProvider>
          <Head>
            <meta
              name='viewport'
              content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
            />
          </Head>
          <main className={inter.variable}>
            {getLayout(<Component {...pageProps} />)}
            <Login />
            {/* <LoginResult /> */}
            <ArticleBottomAlert />
            <CookieConsentContent />
          </main>
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}

export default appWithTranslation(App);
