export const isProd =
  process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

// App 6
export const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = '1' == AppType;

// name
const getAppName = () => {
  return 'Instantknow.ai';
};

export const lowerName = getAppName().replace(/ /g, '_').toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.webp`;
// app net logo
const getAppNetLogo = `https://res-gpts.pumpsoul.com/logo/${lowerName}.webp`;
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.webp`;
// app ico
const getAppICO = `/favicon.ico`;

// const getWebHost = `https://www.instantknow.ai`;
// const getBlogHost = `https://www.instantknow.ai`;

export const currentHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('-test.')) {
    return `www-test.instantknow.ai`;
  } else {
    return `www.instantknow.ai`;
  }
};

const getWebHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('-test.')) {
    return `https://www-test.instantknow.ai`;
  } else {
    return `https://www.instantknow.ai`;
  }
};

// app title
const getAppTitle = () => {
  return 'DolphinRadar | Advanced Instagram Account Monitoring & Analytics Tool';
};

// app desc
const getAppDesc = () => {
  return `Track and analyze Instagram activity with DolphinRadar. Discover hidden insights and stories behind interactions, like an Instagram spy.`;
};

// app sub title
const getAppHeadSubTitle = () => {
  return `DolphinRadar | Advanced Instagram Account Monitoring & Analytics Tool`;
};

//
const getFirebaseConfig = () => {
  return {
    apiKey: "AIzaSyBnGr0byLwy8iEyYHb_pvpLc0hXr604Vas",
    // authDomain: "instantknow-156a2.firebaseapp.com",
    authDomain: `${currentHost()}/fb-auth`,
    projectId: "instantknow-156a2",
    storageBucket: "instantknow-156a2.appspot.com",
    messagingSenderId: "175368240676",
    appId: "1:175368240676:web:d8de9e0f96a368406da2b1",
    measurementId: "G-684YQHC07X"
  };
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
  webHost: string;
  blogHost: string;
  clarityId: string;
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  namePath: getAppLogoName,
  icoPath: getAppICO,
  title: getAppTitle(),
  headSubTitle: getAppHeadSubTitle(),
  desc: getAppDesc(),
  firebaseConfig: getFirebaseConfig(),
  webHost: getWebHost(),
  clarityId: isProd ? `oan5cm7s1j` : '',
};

// 首先，定义一个枚举来表示不同的页面类型
export enum PageType {
  Normal = 'normal',
  Normal2 = 'how-can-tracking-prices-help-you-win-more-competitive-deals',
  Normal3 = 'catch-trending-topics-be-the-first-to-know-whats-hot',
  Normal4 = 'track-every-change-stay-aware-of-every-strategy',
}
// 然后，定义一个接口来描述每种页面类型的元数据
interface PageMetadata {
  titleKey: string;
  descriptionKey: string;
  title1Key: string;
  title2Key: string;
  titleDescKey: string;
  introduces: Introduce[];
}

interface Introduce {
  titleKey: string;
  descKey: string;
  imgKey: string;
}

// 转换函数
// function convertToIntroduce(item: (typeof IntroduceVisa)[0]): Introduce {
//   return {
//     titleKey: item.titleKey,
//     descKey: item.descKey,
//     imgKey: item.imgUrl, // 注意这里将 imgUrl 映射到 imgKey
//   };
// }

// 创建一个映射，将页面类型与其元数据关联起来
export const pageMetadataMap: Record<PageType, PageMetadata> = {
  [PageType.Normal]: {
    titleKey: `Instantknow-The ultimate website checker. Monitor website changes.`,
    descriptionKey: `Never miss a website update again with InstantKnow. Compare page versions, capture visual content, and automate monitoring—everything you need to stay competitive.`,
    title1Key: 'Stay Ahead of Every Website Change, Big or Small.',
    title2Key: '',
    titleDescKey: `Website changes happen fast, and missing them can be costly. InstantKnow offers real-time website monitoring, keeping you updated on every adjustment. Stay informed, stay ahead, and never let important updates pass unnoticed!`,
    introduces: [],
  },
  [PageType.Normal2]: {
    titleKey: `Best Ways to Track Prices and Win More Competitive Deals | Instantknow`,
    descriptionKey: 'Set your monitoring job to track website changes, detect content updates, and capture screenshots. Receive email, push, or mobile notifications for any change.',
    title1Key: `How Can Tracking Prices Help You Win More Competitive Deals?`,
    title2Key: '',
    titleDescKey: `Don't miss out on competitive deals. With InstantKnow's real-time price tracking, you can react fast to market shifts and boost your margins. Track prices effortlessly and stay ahead in every deal!`,
    introduces: [],
  },
  [PageType.Normal3]: {
    titleKey: `How to Catch Trending Topics and Be the First to Know What's Hot | Instantknow`,
    descriptionKey: 'Track price changes with Instantknow. Set your monitoring job to track website changes, detect content updates, and capture screenshots. Receive email, push, or mobile notifications for any change.',
    title1Key: `Catch Trending Topics, Be the First to Know What's Hot`,
    title2Key: '',
    titleDescKey: `Be the first to know what's trending. With InstantKnow's trending topics alerts, you can create impactful content that leads the conversation. Don't just follow trends—own them!`,
    introduces: [],
  },
  [PageType.Normal4]: {
    titleKey: `Effective Strategies to Track Every Change and Stay Ahead | Instantknow`,
    descriptionKey: 'Catch trending topics and be the first to know with Instantknow. Set your monitoring job to track website changes, detect content updates, and capture screenshots. Receive email, push, or mobile notifications for any change.',
    title1Key: `Track Every Change, Stay Aware of Every Strategy`,
    title2Key: '',
    titleDescKey: `What are similar product websites up to? With InstantKnow, you'll know their every move—whether it's a new marketing tactic or a website overhaul. Gain valuable insights and always stay one step ahead!`,
    introduces: [],
  },
};

export const commonImgPath = `https://res-front.pumpsoul.com/ika-prod/image`;

export const kTmpUidKey = '17_user_uuid'
