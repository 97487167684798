import axios from 'axios';
import Cookies from 'js-cookie';
import { kTmpUidKey } from './app-common';

const instance = axios.create({
  baseURL: process.env.NEXT_APP_API_HOST,
  timeout: 60000,
  headers: {
    tenantId: `${process.env.NEXT_APP_TENANTID}`,
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('ika_userToken');
    // const token = ls_getItem('ika_userToken');
    if (token !== undefined) {
      config.headers['Authorization'] = token;
    }
    const storedUuid = Cookies.get(kTmpUidKey);
    if (token === undefined && storedUuid !== undefined) {
      config.headers['tmp-uid'] = storedUuid;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
